// Bet tabs
.bets-holder {
  margin: 0 auto;
}

.bet-tabs-nav,
.pod-tabs-nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-left: 0;
  list-style: none;
  padding-right: 13px;
  padding-left: 13px;
  margin-right: auto;
  margin-left: auto;
}

.bet-tabs-nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 80px;
  border: 1px solid rgba(255, 191, 26, 0.2);
  @include boxShadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  background-color: $blue-100;
  border-radius: 5px;
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  @include transition(0.3s ease-in-out all);
  cursor: pointer;
  margin: 0 2px;

  &.r7css {
    font-size: 16px;
  }

  span {
    text-align: center;
    line-height: 15px;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    overflow: hidden;
    width: 100%;
    // word-break: break-all;
    // white-space-collapse: collapse;
    // text-wrap: balance;
  }

  &.bet-tabs-nav-item-disabled {
    opacity: 0.3;

    &:hover {
      border: 1px solid rgba(255, 191, 26, 0.2);
    }
  }

  &:hover {
    border: 1px solid rgba(255, 191, 26, 0.5);
  }

  .bet-tabs-icon-holder {
    width: 32px;
    height: 32px;
    position: relative;
  }

  .bets-active,
  .bets-inactive {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include transition(0.6s ease-in-out all);
  }

  .bets-inactive {
    opacity: 1;
  }
  .bets-active {
    opacity: 0;
  }

  &.selected-bet-tab {
    background-color: $yellow-100;
    color: $blue-100;

    .bets-inactive {
      opacity: 0;
    }
    .bets-active {
      opacity: 1;
    }
  }
}

// Pod tabs

.pod-tabs-nav {
  margin-bottom: 0;
  background-color: $blue-300;
  height: 45px;

  @media (min-width: 1024px) {
    background-color: $blue-400;
  }
}

.pod-tabs-nav-item {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  @include transition(0.3s ease-in-out all);
  cursor: pointer;
  border-bottom: 1px solid #454b7a;
  opacity: 0.4;
  padding-bottom: 9px;
  text-align: center;
  line-height: .95;

  &.r7css {
    font-size: 16px;
  }

  &.selected-pod-tab {
    opacity: 1;
    border-bottom: 2px solid $yellow-100;
    padding-bottom: 0.5rem;
  }
}

.pod-tabs-nav-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-semibold;
  font-size: 10px;
  line-height: 16px;
  padding: 0 0.325rem;
  border-radius: 8px;
  background-color: $blue-800;
  margin-left: 0.5rem;

  &.r7css {
    font-size: 12px;
  }
}

.pod-tabs-panel-holder {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.sticky-header-holder {
  position: relative;
  z-index: 1;
}

.sticky-header {
  top: 60px !important;
  @media (min-width: 1024px) {
    width: 100% !important;
    position: relative !important;
    top: 0 !important;
    z-index: 1;
    @include boxShadow(0px 0px 10px rgba(0, 0, 0, 0.4));
  }
}
