.play-now-footer {
  max-height: 36px;
  width: 100%;
  background-color: $blue-200;
  border-bottom: 1px solid rgba(69, 75, 122, 0.4);
  padding: 0.5rem 15px;
  @include transition(0.3s linear all);
  overflow: hidden;
  position: absolute;
  bottom: 74px;
  z-index: 10;

  @media (min-width: 1024px) {
    bottom: 0;
  }


  .play-now-footer-mmsb-holder {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: -0.25rem;
    padding-right: 18px;
  }

  &.active {
    height: auto;
    max-height: 68px;
  }

  &.fullscreen {
    max-height: 100%;
    top: 0;
    transition: none !important;
    overflow: auto;
    z-index: 99;
  }

  .mmsb-info-content {
    margin: 0.25rem;
    padding: 0 0.25rem;
    border-radius: 5px;

    &.win-small {
      background-color: $blue-700;
      color: $blue-800;
    }
    &.win {
      background-color: $green-100;
      color: $green-200;
    }
    &.win-big {
      background-color: #F7FD04;
      color: #141945;
    }
    &.win-legendary {
      background-color: #db1f35;
      color: #f8ced3;
    }
  }
}

.play-now-footer-toggle {
  width: 37px;
  height: 20px;
  background-color: $blue-700;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  @include transition(0.6s ease-in-out all);

  &.active {
    background-color: $yellow-200;

    svg {
      -webkit-transform: rotateX(180deg);
      transform: rotateX(180deg);

      path {
        stroke: $blue-200;
      }
    }
  }
}

.play-now-footer-fullscreen-wrapper {
  position: sticky;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 4px;
}
.play-now-footer-toggle-wrapper {
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: flex-end;
  padding-right: 4px;
  z-index: 1;
}

.play-now-footer-fullscreen {
  width: 37px;
  height: 20px;
  background-color: $blue-700;
  border-radius: 10px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  @include transition(0.6s ease-in-out all);

  &.fullscreen {
    background-color: $yellow-200;

    svg {

      path {
        fill: $blue-200;
      }
    }
  }
}
