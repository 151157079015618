@font-face {
  font-family: TitilliumWeb-Regular;
  src: url(../fonts/TitilliumWeb-Regular.ttf);
}

@font-face {
  font-family: TitilliumWeb-SemiBold;
  src: url(../fonts/TitilliumWeb-SemiBold.ttf);
}

@font-face {
  font-family: TitilliumWeb-Bold;
  src: url(../fonts/TitilliumWeb-Bold.ttf);
}

@font-face {
  font-family: Roboto-Bold;
  src: url(../fonts/Roboto-Bold.ttf);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(../fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  src: url(../fonts/Roboto-Regular.ttf);
}

html,
body {
  overflow-x: hidden;
  width: 100%;
}

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

html,
body,
#root,
.App {
  margin: 0;
}

.full-height {
  height: 100%;
}

body {
  background-color: $blue-300;
  padding-top: 60px;

  @media (min-width: 1024px) {
    padding-top: 84px;
  }
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: 100%;

  @media (min-width: 1400px) {
    //max-width: 1240px;
    max-width: 1600px;
  }
}

canvas {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  @media (min-width: 1024px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.rocketman-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  @media (min-width: 1024px) {
    height: 100%;
  }
}

.rocketman-scroll-holder {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 42px;

  @media (min-width: 1024px) {
    padding-bottom: 36px;
  }

  @media (max-width: 1024px) and (max-height: 767px) {
    zoom: .7;
    padding-bottom: 52px;
  }

  @media (max-width: 359px) {
    zoom: .7;
    padding-bottom: 52px;
  }
}

.page-holder {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.with-provably {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    min-height: 100%;

    @media (min-width: 1024px) {
      min-height: calc(100vh - 84px);
    }
  }

  &.with-bottom-navigator {
    padding-bottom: 74px;

    @media (min-width: 1024px) {
      padding-bottom: 0;
    }
  }

  &.full-height {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    @media (min-width: 1024px) {
      height: calc(100vh - 74px);
    }
  }
}

.menu-page {
  display: flex;
  flex-direction: column;
}

.menu-buttons-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 -5px;
  margin-top: 3rem;
  padding-bottom: 1rem;
}

.menu-buttons-holder {
  .menu-buttons {
    padding: 0 5px;
  }
}

.menu-buttons {
  display: flex;
  min-width: 135px;
  padding: 0 5px;
  flex: 1;

  &:hover {
    text-decoration: none;
  }
}

.video-holder,
.provably-fair-image-holder {
  border-radius: 5px;
  margin-bottom: 2.5rem;

  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  text-align: center;

  img {
    width: 100%;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.provably-fair-image-holder {
  margin-bottom: 1rem;
}

.how-to-play-page {
  .steps-holder {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 1024px) {
      flex-direction: row;
    }

    @media (min-width: 1200px) {
      width: 80%;
    }
  }

  .video-holder {
    width: 100%;

    @media (min-width: 1024px) {
      width: 80%;
    }
  }
}

//.detailed-rulles-page {
.steps-holder {
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
  }
}

//}

.steps-holder {
  margin-bottom: 4rem;
  width: 100%;
  display: flex;
  margin: auto;
}

.video-holder {
  width: 100%;
  display: flex;
  margin: auto;
}

.provably-fair-explainer {
  margin: 2rem 0;
}

.emoji-container {
  position: absolute;
  bottom: 72px;
  right: 1px;

  &.hide-emoji {
    display: none;
  }
}

aside.emoji-picker-react {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
  background: linear-gradient(116.41deg,
      #d5d7ea 33.41%,
      #ffbf1a 212.04%) !important;
}

.emoji-search,
.emoji-picker-react .emoji-group:before {
  color: $blue-400 !important;
  z-index: 5 !important;
  position: relative !important;
  background: transparent !important;
}

.emoji-picker-react input.emoji-search {
  border: 1px solid $blue-400 !important;
}

.balance-currency {
  font-size: 10px;
  padding-top: 4px;
  margin-left: 4px;

  &.r7css {
    font-size: 12px;
  }
}

.chat-gifs-holder {
  position: absolute;
  bottom: 72px;
  right: 1px;
  height: 320px;
  width: 280px;
  padding: 15px;
  background: linear-gradient(116.41deg, #d5d7ea 33.41%, #ffbf1a 212.04%);
  overflow-y: auto;
  border-top-left-radius: 5px;
}

.chat-gifs-title {
  font-family: sans-serif;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;

  &.meteor {
    justify-content: center;
  }
}

.chat-gifs-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.25rem -0.25rem;
}

.chat-gifs-category {
  position: relative;
  flex: 1;
  margin: 0.25rem;
  background-size: cover;
  border-radius: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .chat-gifs-category-title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    @include transition(0.3s all ease-in-out);
    border-radius: 5px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  &.loss-category {
    // background-size: cover;
    // background: url(https://media3.giphy.com/media/EXHHMS9caoxAA/giphy.gif) no-repeat center center fixed;
  }
}

.animation-off {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #3bc4f2;
}

.animation-off-multiplier {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  font-size: 72px;
  font-family: $font-bold;
  color: white;

  @media (min-width: 1024px) {
    margin-top: 55px;
    font-size: 84px;
  }
}

.jackpot-holder {

  .odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
  .odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner,
  .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
  .odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    -webkit-transition-duration: 35s !important;
    -moz-transition-duration: 35s !important;
    -ms-transition-duration: 35s !important;
    -o-transition-duration: 35s !important;
    transition-duration: 35s !important;
  }

  &.on-load {

    .odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
    .odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner,
    .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
    .odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
      -webkit-transition-duration: 0.1s !important;
      -moz-transition-duration: 0.1s !important;
      -ms-transition-duration: 0.1s !important;
      -o-transition-duration: 0.1s !important;
      transition-duration: 0.1s !important;
    }
  }
}

.menu-center {
  display: flex;
  margin: 2rem auto !important;
  width: 100%;
  border-radius: 5px;

  @media (min-width: 768px) {
    width: 80%;
  }

  @media (min-width: 1024px) {
    width: 50%;

    &.sound-menu-img {
      width: 30%;
    }
  }
}

.shortcut-table {
  width: 100%;

  @media (min-width: 1024px) {
    width: auto;
  }

  thead {
    tr {
      border-bottom: 1px solid white;

      th {
        padding: 0 0.5rem;

        &:first-child {
          border-right: 1px solid white;
          width: 30%;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid white;

      td {
        padding: 0 0.5rem;

        &:first-child {
          border-right: 1px solid white;
          width: 30%;
        }
      }
    }
  }
}

.promo-credits-main-holder {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
    width: 815px;
    margin: 1rem auto;
  }

  @media (min-width: 1200px) {
    width: 845px;
  }

  &.reversed {
    flex-direction: column;

    @media (min-width: 1024px) {
      flex-direction: row-reverse;
    }

    .promo-credits-image {
      @media (min-width: 1024px) {
        justify-content: flex-start;
      }
    }
  }

  &.hidden {
    display: none;
  }
}

.promo-credits-inline-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.promo-credits-image {
  display: flex;
  margin-left: 0;
  pointer-events: none;
  width: 100%;

  .astronaut-pop-up-body {
    width: 100%;
  }

  img {
    width: 70%;
  }

  @media (min-width: 768px) {
    // margin: 0 auto;
  }

  @media (min-width: 1024px) {
    justify-content: flex-end;
    flex: 1;
  }
}

.bet-tooltip {
  font-family: $font-semibold !important;
  font-size: 12px !important;
  color: $blue-300 !important;

  &.r7css {
    font-size: 14px !important;
  }
}

.advanced-autoplay-tooltip {
  font-family: $font-semibold !important;
  font-size: 12px !important;
  color: $blue-300 !important;
  max-width: 300px;

  &.r7css {
    font-size: 14px !important;
  }
}

.go-to-home {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media (min-width: 1024px) {
    display: block;
  }
}

.xs-flag {
  transform: rotate(180deg);
}

.defined-username {
  height: 36px;
  width: 200px;
  background-color: rgba(37, 43, 99, 0.8);
  border-radius: 5px;
  border: none;
  font-family: $font-regular;
  font-size: 16px;
  color: white;
  padding: 0.5rem;
  margin-top: 20px;

  &:focus {
    outline: none;
  }
}


.demo-badge {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -6px;
  left: -7px;
  z-index: 99;
}

.demo-badge .sticker {
  font-family: $font-bold;
  font-size: 14px;
  color: white;
  text-align: center;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  position: relative;
  padding: 7px 0;
  left: -29px;
  top: 17px;
  width: 120px;
  background-color: $red-100;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#BFDC7A), to(#8EBF45));
  background-image: -webkit-linear-gradient(top, #EF5350, #F44336);
  background-image: -moz-linear-gradient(top, #EF5350, #F44336);
  background-image: -ms-linear-gradient(top, #EF5350, #F44336);
  background-image: -o-linear-gradient(top, #EF5350, #F44336);
  color: #FFFFFF;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.user-error-message {
  font-family: $font-regular;
  font-size: 14px;
  color: $red-100;
  padding-top: 5px;
  height: 30px;
}

.confetti-canvas-holder {
  canvas {
    z-index: 10 !important;
  }
}

.meteor-shower-bar {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  position: relative;
  z-index: 9;
  padding-top: .25rem;

  img {
    margin-right: 0.5rem;
  }

  p {
    font-family: $font-regular;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.meteor-shower-holder {
  bottom: 100px;
  left: 0;
  right: 0;
  width: 100%;
  border-top-right-radius: 5px;
  background: #070a2a;
  border-bottom: none;
  height: auto;

  .chat-gifs-title {
    color: white;
  }
}

.meteor-shower-total-bet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.msm-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.msm-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: rgba(37, 43, 99, 0.8);
  width: auto;
  font-family: $font-regular;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  padding: .5rem;
  text-align: center;
  height: 24px;
  border-radius: 12px;
  line-height: 19px;
}

.msm-currency {
  font-size: 10px;
  margin-top: 2px;
}

.msm-claim-btn {
  text-align: right;

  .primary-button {
    height: 26px;
    font-size: 14px;
  }
}

.masked-username {}

.use-insurance {
  font-family: $font-bold;
  font-size: 12px;
  line-height: 18.25px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0.25rem;
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: .5rem 0;
  margin-top: -1rem;
}

.control {
  display: block;
  position: relative;
  padding-right: 30px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 12px;
  padding-top: 6px;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator {
  position: absolute;
  top: 2px;
  right: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border-radius: 5px;
}

.control:hover input~.control__indicator,
.control input:focus~.control__indicator {
  background: #ffffff;
}

.control input:checked~.control__indicator {
  background: #fabf41;
}

.control:hover input:not([disabled]):checked~.control__indicator,
.control input:checked:focus~.control__indicator {
  background: #fabf41;
}

.control input:disabled~.control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.control input:checked~.control__indicator:after {
  display: block;
}

.control--checkbox .control__indicator:after {
  left: 7px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid #070a2b;
  border-width: 0px 3px 3px 0;
  transform: rotate(45deg);
}

.control--checkbox input:disabled~.control__indicator:after {
  border-color: #7b7b7b;
}

.control input:checked:disabled~.control__indicator {
  background: #fabf41;
  opacity: 0.6;
  pointer-events: none;
}

.bet-insurance-protected-wrapper {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
}

.bip-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  background-color: #070a2b;
  border-bottom-left-radius: 5px;
}

.jackpot-cash-holder {
  .odometer-numbers>span:last-child {
    transition-duration: 0.7s !important;
  }
}

.odometer-dot-coma {
  width: 5px;
}

.react-switch-custom {
  min-width: 100px;
  
  .react-switch-bg {
    width: 100% !important;

    .switcher-off-inactive {
      width: 50% !important;
    }

    > div:first-child {
      width: 100% !important;
    }

    > div:nth-child(2) {
      width: 50% !important;
      display: flex;
      justify-content: center;
    }
  }

  .react-switch-handle {
    width: 50% !important;

    div {
      width: 100% !important;
    }
  }

  &.move-left {
    .react-switch-handle {
      transform: translateX(98%) !important;
    }
  }
}

.landscape-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(7, 10, 43, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-bold;
  font-size: 40px;
  color: white;
  z-index: 9999;
  padding: 2rem;
  text-align: center;
  line-height: 1.2;
}